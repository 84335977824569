import Teamwithdrawalcontainer from "../containers/Teamwithdrawal";
import Layout from "../layouts"
const Teamwithdrawal = () => {

  return (
    <Layout Title="Team Withdrawal History">
     <Teamwithdrawalcontainer/>
    </Layout>
  );
}

export default Teamwithdrawal;