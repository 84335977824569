import VipwithdrawalContainer from "../containers/Vipwithdrawal";
import Layout from "../layouts"
const Vipwithdrawal = () => {

  return (
    <Layout Title="VIP Withdrawal History">
     <VipwithdrawalContainer/>
    </Layout>
  );
}

export default Vipwithdrawal;