import data from "../../data/static.json";

import Breadcumb from "../../components/Breadcumb";

const ReferralContainer = () => {
  return (
    <>
      <Breadcumb title="Join Now" text="Home" />
      {/* <AboutOurCollection
        ClassItem="about-us-area section-padding-100"
        ClassBxImgUp="col-12 col-lg-6 offset-lg-0 col-md-12 "
        ClassBxImg="welcome-meter floating-anim"
        ClassBxText="col-12 col-lg-6 offset-lg-0 mt-s"
        data={data[0].AboutOurCollection}
        AboutOurCollectionImg={data[0].HomeDemo1.AboutOurCollectionImg}
      />
      <Subscribe data={data[0].Subscribe} /> */}

      <section
        className="section-padding-100"
        style={{ paddingBottom: "120px" }}
      >
        <div className="container-fluid">
          <div className="section-heading text-center">
            <div
              className="dream-dots justify-content-center aos-init aos-animate"
              data-aos="fade-up"
            >
              {/* <span className="gradient-t">Discover Our New Collection</span> */}
            </div>
            <h2 data-aos="fade-up" className="aos-init aos-animate">
              Join Now
            </h2>
            <p data-aos="fade-up" className="aos-init">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis
              accumsan nisi Ut ut felis congue nisl hendrerit commodo.
            </p>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-4 col-lg-4">
              <div className="contact_form">
                <form
                 id="main_contact_form"
                 >
                  <div className="row">
                    
                    <div
                      className="col-12"
                      
                    >
                      <div className="group" data-wow-delay="0.5s">
                      <label>Sponsor Address</label>
                        <input type="text" name="address" id="address"/>
                        <span className="highlight"></span>
                        <span className="bar"></span>
                      
                      </div>
                    </div>
                    <div
                      className="col-12 "
                     
                    >
                      <div className="group" data-wow-delay="0.5s">
                      <label>Fantom Address</label>
                        <input
                          type="text"
                          name="address"
                          id="address"
                         
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                       
                      </div>
                    </div>
                    <div className="col-12" >
                      <div className="group" data-wow-delay="0.5s">
                      <label>FIZO Token Received</label>
                        <input
                          type="text"
                          name="token"
                          id="token"
                           />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                       
                      </div>
                    </div>
                    
                 
               </div>
               <div
                      className="row mt-2 mb-2 text-center "
                      
                    >
                        <div className="col-md-4 mb-2">
                        <button type="submit" className="btn more-btn" style={{width:"100%"}}>
                        200 FTM
                      </button>
                        </div>
                        
                        <div className="col-md-4  mb-2">
                        <button type="submit" className="btn more-btn" style={{width:"100%"}}>
                        500 FTM
                      </button>
                        </div>
                        
                        <div className="col-md-4  mb-2">
                        <button type="submit" className="btn more-btn" style={{width:"100%"}}>
                        1000 FTM
                      </button>
                        </div>
                        <div className="col-md-4  mb-2">
                        <button type="submit" className="btn more-btn" style={{width:"100%"}}>
                        5000 FTM
                      </button>
                        </div>
                        
                        <div className="col-md-4  mb-2" >
                        <button type="submit" className="btn more-btn" style={{width:"100%"}}>
                        10000 FTM
                      </button>
                        </div>
                        
                        <div className="col-md-4  mb-2" >
                        <button type="submit" className="btn more-btn" style={{width:"100%"}}>
                        13000 FTM
                      </button>
                        </div>
                        <div className="col-md-12 mt-2" >
                        <button type="submit" className="btn more-btn1" style={{width:"100%"}}>
                        Create Account
                      </button>
                        </div>
                        <div className="col-md-12 mt-4 text-center ">
                        <h6 data-aos="fade-up" class="aos-init aos-animate" style={{color:"#fff"}}>Have any Account?</h6>
                        <button type="submit" className="btn more-btn1 mt-3">
                     Connect Now
                      </button>
                    </div>
               </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReferralContainer;
