import data from "../../data/static.json";

import Breadcumb from "../../components/Breadcumb";

const VipwithdrawalContainer = () => {
  return (
    <>
      <Breadcumb title="VIP Withdrawal History" text="Home" />
      {/* <AboutOurCollection
        ClassItem="about-us-area section-padding-100"
        ClassBxImgUp="col-12 col-lg-6 offset-lg-0 col-md-12 "
        ClassBxImg="welcome-meter floating-anim"
        ClassBxText="col-12 col-lg-6 offset-lg-0 mt-s"
        data={data[0].AboutOurCollection}
        AboutOurCollectionImg={data[0].HomeDemo1.AboutOurCollectionImg}
      />
      <Subscribe data={data[0].Subscribe} /> */}

      <section
        className="section-padding-100"
        style={{ paddingBottom: "120px" }}
      >
        <div className="container">
        
          <div className="row justify-content-center">
            <div className="col-12 col-md-12">
              <div className="purchase-table">
               <div className="table-responsive">
                <table className="table">
                <thead>
                    <tr>
                        <th>Amount</th>
                     
                        <th>Date Time</th>
                    
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>2000</td>
                      
                        <td>30-02-2020</td>
                    </tr>
                </tbody>
                </table>
               </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VipwithdrawalContainer;
