import HomeContainer from '../containers/Home'
import Footer from '../layouts/Footer';
const Home = () => {

  return (
    <>
      <HomeContainer />
      <Footer />
    </>
  );
}

export default Home;

