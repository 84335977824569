import LeveldetailContainer from "../containers/Leveldetail";
import Layout from "../layouts"
const Leveldetail = () => {

  return (
    <Layout Title="Level Details">
      <LeveldetailContainer />
    </Layout>
  );
}

export default Leveldetail;