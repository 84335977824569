import data from "../../data/static.json";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Breadcumb from "../../components/Breadcumb";

const PurchasesellContainer = () => {
  return (
    <>
      <Breadcumb title="BUY & SELL" text="Home" />
      {/* <AboutOurCollection
        ClassItem="about-us-area section-padding-100"
        ClassBxImgUp="col-12 col-lg-6 offset-lg-0 col-md-12 "
        ClassBxImg="welcome-meter floating-anim"
        ClassBxText="col-12 col-lg-6 offset-lg-0 mt-s"
        data={data[0].AboutOurCollection}
        AboutOurCollectionImg={data[0].HomeDemo1.AboutOurCollectionImg}
      />
      <Subscribe data={data[0].Subscribe} /> */}

      <section
        className="section-padding-100"
        style={{ paddingBottom: "120px" }}
      >
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12 col-md-4 col-lg-4">
              <div className="contact_form">
                <Tabs>
                  <TabList>
                    <Tab>Buy FIZO</Tab>
                    <Tab>Sell FIZO</Tab>
                  </TabList>
                  <TabPanel>
                    <form id="main_contact_form">
                      <div className="row">
                        <div
                          className="col-12 aos-init aos-animate"
                          data-aos="fade-up"
                        >
                          <div className="group" data-wow-delay="0.5s">
                            <label>FTM amount</label>
                            <input type="text" name="address" id="address" />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                          </div>
                        </div>

                        <div
                          className="col-12 aos-init aos-animate"
                          data-aos="fade-up"
                        >
                          <div className="group" data-wow-delay="0.5s">
                            <label>FIZO Token Received</label>
                            <input type="text" name="token" id="token" />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                          </div>
                        </div>

                       
                      </div>
                      <div
                          className="row text-center aos-init aos-animate"
                          data-aos="fade-up"
                        >
                          <div className="col-md-4 mb-2" >
                            <button type="submit" className="btn more-btn" style={{width:"100%"}}>
                              200 FTM
                            </button>
                          </div>

                          <div className="col-md-4  mb-2" >
                            <button type="submit" className="btn more-btn" style={{width:"100%"}}>
                              500 FTM
                            </button>
                          </div>

                          <div className="col-md-4  mb-2" >
                            <button type="submit" className="btn more-btn" style={{width:"100%"}}>
                              1000 FTM
                            </button>
                          </div>
                          <div className="col-md-4  mb-2" >
                            <button type="submit" className="btn more-btn" style={{width:"100%"}}>
                              5000 FTM
                            </button>
                          </div>

                          <div className="col-md-4  mb-2" >
                            <button type="submit" className="btn more-btn" style={{width:"100%"}}>
                              10000 FTM
                            </button>
                          </div>

                          <div className="col-md-4  mb-2" > 
                            <button type="submit" className="btn more-btn" style={{width:"100%"}}>
                              13000 FTM
                            </button>
                          </div>
                          <div className="col-md-12 mt-4" >
                            <button type="submit" className="btn more-btn1" style={{width:"100%"}}>
                              Buy FIZO
                            </button>
                          </div>
                        </div>
                    </form>
                  </TabPanel>
                  <TabPanel>
                    <form id="main_contact_form">
                      <div className="row">
                        <div
                          className="col-12 aos-init aos-animate"
                          data-aos="fade-up"
                        >
                          <div className="group" data-wow-delay="0.5s">
                            <label>Your Avaible Withdraw FTM</label>
                            <input type="text" name="address" id="address" />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                          </div>
                        </div>

                        <div
                          className="col-12 aos-init aos-animate"
                          data-aos="fade-up"
                        >
                          <div className="group" data-wow-delay="0.5s">
                            <label>Deduction FTM</label>
                            <input type="text" name="token" id="token" />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                          </div>
                        </div>
                        <div
                          className="col-12 aos-init aos-animate"
                          data-aos="fade-up"
                        >
                          <div className="group" data-wow-delay="0.5s">
                            <label>Final FTM Received</label>
                            <input type="text" name="token" id="token" />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                          </div>
                        </div>
                      
                      </div>
                      <div
                          className="row text-center aos-init aos-animate"
                          data-aos="fade-up"
                        >
                          <div className="col-md-4 mb-2">
                            <button type="submit" className="btn more-btn" style={{width:"100%"}}>
                              1 %
                            </button>
                          </div>

                          <div className="col-md-4  mb-2">
                            <button type="submit" className="btn more-btn" style={{width:"100%"}}>
                              100 %
                            </button>
                          </div>

                          <div className="col-md-12 mt-4">
                            <button type="submit" className="btn more-btn1" style={{width:"100%"}}>
                              Sell FIZO
                            </button>
                          </div>
                        </div>
                    </form>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PurchasesellContainer;
