import HomeDemo2Container from '../template/HomeDemo2'
import Layout from "../layouts"
const HomeDemo2 = () => {

  return (
    <Layout Title="HomeDemo2">
      <HomeDemo2Container />
    </Layout>
  );
}

export default HomeDemo2;