import Purchasesellcontainer from "../containers/Purchasesell";
import Layout from "../layouts"
const Purchasesell = () => {

  return (
    <Layout Title="BUY & SELL">
      <Purchasesellcontainer/>
    </Layout>
  );
}

export default Purchasesell;