import Withdrawhistorycontainer from "../containers/Withdrawhistory";
import Layout from "../layouts"
const Withdrawhistory = () => {

  return (
    <Layout Title="Withdraw History">
      <Withdrawhistorycontainer/>
    </Layout>
  );
}

export default Withdrawhistory;