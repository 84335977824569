import data from "../../data/static.json";

import Breadcumb from "../../components/Breadcumb";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const LeveldetailContainer = () => {
  return (
    <>
      <Breadcumb title="Level Details" text="Home" />
      {/* <AboutOurCollection
        ClassItem="about-us-area section-padding-100"
        ClassBxImgUp="col-12 col-lg-6 offset-lg-0 col-md-12 "
        ClassBxImg="welcome-meter floating-anim"
        ClassBxText="col-12 col-lg-6 offset-lg-0 mt-s"
        data={data[0].AboutOurCollection}
        AboutOurCollectionImg={data[0].HomeDemo1.AboutOurCollectionImg}
      />
      <Subscribe data={data[0].Subscribe} /> */}

      <section
        className="section-padding-100"
        style={{ paddingBottom: "120px" }}
      >
        <div className="container">
        
          <div className="row ">
            <div className="col-md-4">
              <div className="stacking__project-item">
                <div className="project-item">
                <h4 className="w-text title wow fadeInUp" data-wow-delay="0.2s">
                  Level 1 Details
                </h4>
                <ul>
                    <li>Team 0</li>
                    <li>Income 0.000</li>
                    <li>Business 0.000</li>
                </ul>

                </div>
                <div className="project-icon">
                    <img src="img/icons/1.png" alt="icon" width="50px" height="50px"/>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="stacking__project-item">
              <div className="project-item">
                <h4 className="w-text title wow fadeInUp" data-wow-delay="0.2s">
                  Level 2 Details
                </h4>
                <ul>
                    <li>Team 0</li>
                    <li>Income 0.000</li>
                    <li>Business 0.000</li>
                </ul>
                </div>
                <div className="project-icon">
                    <img src="img/icons/2.png" alt="icon" width="50px" height="50px"/>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="stacking__project-item">
              <div className="project-item">
                <h4 className="w-text title wow fadeInUp" data-wow-delay="0.2s">
                  Level 3 Details
                </h4>
                <ul>
                    <li>Team 0</li>
                    <li>Income 0.000</li>
                    <li>Business 0.000</li>
                </ul>
                </div>
                <div className="project-icon">
                    <img src="img/icons/3.png" alt="icon" width="50px" height="50px"/>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="stacking__project-item">
              <div className="project-item">
                <h4 className="w-text title wow fadeInUp" data-wow-delay="0.2s">
                  Level 4 Details
                </h4>
                <ul>
                    <li>Team 0</li>
                    <li>Income 0.000</li>
                    <li>Business 0.000</li>
                </ul>
                </div>
                <div className="project-icon">
                    <img src="img/icons/4.png" alt="icon" width="50px" height="50px"/>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="stacking__project-item">
              <div className="project-item">
                <h4 className="w-text title wow fadeInUp" data-wow-delay="0.2s">
                  Level 5 Details
                </h4>
                <ul>
                    <li>Team 0</li>
                    <li>Income 0.000</li>
                    <li>Business 0.000</li>
                </ul>
                </div>
                <div className="project-icon">
                    <img src="img/icons/5.png" alt="icon" width="50px" height="50px"/>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="stacking__project-item">
              <div className="project-item">
                <h4 className="w-text title wow fadeInUp" data-wow-delay="0.2s">
                  Level 6 Details
                </h4>
                <ul>
                    <li>Team 0</li>
                    <li>Income 0.000</li>
                    <li>Business 0.000</li>
                </ul>
                </div>
                <div className="project-icon">
                    <img src="img/icons/6.png" alt="icon" width="50px" height="50px"/>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="stacking__project-item">
              <div className="project-item">
                <h4 className="w-text title wow fadeInUp" data-wow-delay="0.2s">
                  Level 7 Details
                </h4>
                <ul>
                    <li>Team 0</li>
                    <li>Income 0.000</li>
                    <li>Business 0.000</li>
                </ul>
                </div>
                <div className="project-icon">
                    <img src="img/icons/7.png" alt="icon" width="50px" height="50px"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LeveldetailContainer;
