import data from "../../data/static.json";

import Breadcumb from "../../components/Breadcumb";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const DashboardContainer = () => {
  return (
    <>
      <Breadcumb title="Dashboard" text="Home" />
      {/* <AboutOurCollection
        ClassItem="about-us-area section-padding-100"
        ClassBxImgUp="col-12 col-lg-6 offset-lg-0 col-md-12 "
        ClassBxImg="welcome-meter floating-anim"
        ClassBxText="col-12 col-lg-6 offset-lg-0 mt-s"
        data={data[0].AboutOurCollection}
        AboutOurCollectionImg={data[0].HomeDemo1.AboutOurCollectionImg}
      />
      <Subscribe data={data[0].Subscribe} /> */}

      <section
        className="section-padding-100"
        style={{ paddingBottom: "120px" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="deposit-box"></div>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-3">
              <div className="deposit-box wow fadeInUp">
                <div>
                <h5>   <img src="img/icons/graph.png" alt="graph" width="40px" className="pr-1"/>Deposit FTM</h5>
                <h2 className="w-text title " data-wow-delay="0.2s">
                  0.00
                </h2>
                </div>
                <div>
               
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="deposit-box wow fadeInUp">
                <div>
                <h5> <img src="img/icons/level.png" alt="graph" width="35px" height="35px" className="pr-1"/>0.00 FTM</h5>
                <h2 className="w-text title" data-wow-delay="0.2s">
                  0.00 FIZO
                </h2>
                </div>
                
              </div>
            </div>
            <div className="col-md-3">
              <div className="deposit-box wow fadeInUp">
                <h5> <img src="img/icons/graph.png" alt="graph" width="40px" className="pr-1"/>POI Divident</h5>
                <h2 className="w-text title" data-wow-delay="0.2s">
                  0.000000
                </h2>
              </div>
            </div>
            <div className="col-md-3">
              <div className="deposit-box wow fadeInUp">
                <h5><img src="img/icons/level.png" alt="graph" width="35px" className="pr-2"/>Level Divident</h5>
                <h2 className="w-text title" data-wow-delay="0.2s">
                  0.000000
                </h2>
              </div>
            </div>
            <div className="col-md-3">
              <div className="deposit-box wow fadeInUp">
                <h5> <img src="img/icons/send.png" alt="graph" width="25px" className="pr-1"/>Direct Business</h5>
                <h2 className="w-text title" data-wow-delay="0.2s">
                  0.000000
                </h2>
              </div>
            </div>
            <div className="col-md-3">
              <div className="deposit-box wow fadeInUp">
                <h5> <img src="img/icons/team.png" alt="graph" width="40px" className="pr-1"/>Team Business</h5>
                <h2 className="w-text title" data-wow-delay="0.2s">
                  0.000000
                </h2>
              </div>
            </div>
            <div className="col-md-3">
              <div className="deposit-box wow fadeInUp">
                <h5> <img src="img/icons/team.png" alt="graph" width="40px" className="pr-1"/>Total Team</h5>
                <h2 className="w-text title" data-wow-delay="0.2s">
                  0.000000
                </h2>
              </div>
            </div>
            <div className="col-md-3">
              <div className="deposit-box wow fadeInUp">
                <h5> <img src="img/icons/withdrawal.png" alt="graph" width="25px" className="pr-1"/>Total Withdraw</h5>
                <h2 className="w-text title" data-wow-delay="0.2s">
                  00.000000
                </h2>
              </div>
            </div>
            <div className="col-md-3">
              <div className="deposit-box wow fadeInUp">
                <h5> <img src="img/icons/withdrawal.png" alt="graph" width="25px" className="pr-1"/>Current Supply</h5>
                <h2 className="w-text title " data-wow-delay="0.2s">
                  0.000000
                </h2>
              </div>
            </div>
          
            
          </div>
          <div className="row">
          <div className="col-md-6">
              <div className="deposit-box wow fadeInUp">
                <h2 className="w-text title" data-wow-delay="0.2s">
                  My Wallet
                </h2>
              </div>
            </div>
            <div className="col-md-6">
              <div className="deposit-box d-flex justify-content-between  wow fadeInUp">
                <h2 className="w-text title" data-wow-delay="0.2s">
                  Share Link!
                </h2>
                <ul className="deposit-link">
                  <li>
                    <Link to="">
                      <i className="fa fa-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <i className="fa fa-twitter"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <i className="fa fa-google-plus"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <i className="fa fa-instagram"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
          <div className="col-md-5">
              <div className="deposit-box  wow fadeInUp">
                <h2 className="w-text title" data-wow-delay="0.2s">
                  Team Withdraw
                </h2>
                <form id="main_contact_form">
                  <div className="row">
                    <div className="col-12">
                      <div className="group" data-wow-delay="0.5s">
                        <label>00.00</label>
                        <input type="text" name="team" id="team" />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                      </div>
                    </div>

                    <div className="col-12 text-center">
                      <div className="col-md-12 mt-2">
                        <button type="submit" className="btn more-btn">
                          Withdraw
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DashboardContainer;
