import ReferralContainer from "../containers/Referral";
import Layout from "../layouts"
const Referral = () => {

  return (
    <Layout Title="Referral">
      <ReferralContainer />
    </Layout>
  );
}

export default Referral;