import Purchasehistorycontainer from "../containers/Purchasehistory";
import Layout from "../layouts"
const Purchasehistory = () => {

  return (
    <Layout Title="Purchase History">
      <Purchasehistorycontainer/>
    </Layout>
  );
}

export default Purchasehistory;