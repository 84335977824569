import DashboardContainer from '../containers/Dashboard';
import Layout from '../layouts';

const Dashboard = () => {

  return (
    <Layout Title="Dashboard">
        <DashboardContainer/>
    </Layout>
  );
}

export default Dashboard;

